import { useReactToPrint } from "react-to-print";
import UploadAgreementModal from "../../components/UploadAgreement";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Signature2 from "../../components/Signature2";
import Signature from "../../components/Signature";
import axios from "axios";
import { toast } from "react-toastify";
import { getClient } from "../../redux/clients/client.slices";
import { threeBusinessWorkingDays } from "../../helpers/threeBusinessDays";
import Loader from "../../components/Loader";

export default function ContractHome() {
  const navigate = useNavigate();

  const [isDownload, setIsDownload] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [imageURL2, setImageURL2] = useState(null);
  const [cancel_before, setCancelBefore] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationName, setLocationName] = useState(null);

  const toggle = () => setIsOpen(!isOpen);
  const toggle2 = () => setIsOpen2(!isOpen2);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch();
  const { id } = useParams();

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setIsOpen(false);
  };

  const sigCanvas2 = useRef({});

  const clear2 = () => sigCanvas2.current.clear();

  const save2 = () => {
    setImageURL2(sigCanvas2.current.getTrimmedCanvas().toDataURL("image/png"));
    setIsOpen2(false);
  };

  const { client, isLoading } = useSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getClient(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(position.coords);
        fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
        )
          .then((response) => response.json())
          .then((data) => {
            setLocationName(data.display_name);
          });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + "agreements/" + id)
        .then((data) => {
          setImageURL(data.data.data.signature1);
          setImageURL2(data.data.data.signature2);
          setCancelBefore(data.data.data.cancel_before);
        });
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      if (imageURL === null) {
        return toast.warn("Please complete signatures");
      }
      if (client?.lead?.secondary_name != null) {
        if (imageURL === null)
          return toast.warn("Client 2 signatures is required");
      }

      setIsPending(true);

      await axios.post(process.env.REACT_APP_API_URL + "agreements", {
        lead: client?.lead?._id,
        signature1: imageURL,
        signature2: imageURL2,
        client: client?._id,
        location: locationName,
        cancel_before: threeBusinessWorkingDays()[2],
        coords: {
          latitude: location?.latitude || null,
          longitude: location?.longitude || null,
        },
      });
      toast.success("successfully signed");

      navigate(`/contract/${client?._id}/success`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsPending(false);
    }
  };

  const totalOnetimeFees = client?.lead?.onetime_fees?.reduce(
    (acc, curr) => (acc += parseFloat(curr.feeAmount)),
    0
  );

  let today = new Date();
  let todayDate = today.getDate();

  if (parseInt(client?.lead?.billing_date) < todayDate) {
    today.setMonth(today.getMonth() + 1);
    today.setDate(parseInt(client?.lead?.billing_date));
  } else {
    today.setDate(client?.lead?.billing_date);
  }

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="w-full flex justify-between rounded-md lg:w-1/2 mx-auto m-10">
        <button
          onMouseOver={() => setIsDownload(true)}
          onMouseOut={() => setIsDownload(false)}
          onClick={handlePrint}
          className="flex text-center shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
        >
          <i class="ri-download-2-fill mr-2"></i>
          Download
        </button>
        {client?.status !== "Agreement signed" &&
          client?.status !== "Addendum signed" &&
          client?.status !== "Addendum Pending" && (
            <button
              onClick={() => setShowUpload(!showUpload)}
              className="flex text-center shadow ring-1 ring-[#0E669B] hover:bg-[#010101] hover:text-[#fcfdfd] focus:shadow-outline focus:outline-none text-xs py-3 px-10 rounded"
            >
              <i class="ri-upload-cloud-2-line mr-2"></i>
              Upload copy
            </button>
          )}
      </div>
      <section
        ref={componentRef}
        className={`border min-h-1/2 w-full rounded-md ${
          isDownload ? "lg:w-screen" : "lg:w-1/2"
        }  mx-auto m-10`}
      >
        <div className="h-40 flex flex-col justify-center items-center rounded-t-md bg-gradient-to-r from-[#021521] to-[#083757]">
          <h1 className="text-gray-300">AGREEMENT FOR</h1>
          <h1 className="text-white text-center px-4">
            SERVICE AND INSTALLATION OF NETWORK VIDEO RECORDER SYSTEMS
          </h1>
          <p className="text-gray-300 my-4">
            Customer ID:{" "}
            <span className="text-white">{client?.customer?.id}</span>
          </p>
        </div>
        <div className="p-10 flex flex-col justify-center">
          <div class="container">
            <div>
              <h1 className="underline">
                1. CLIENT INFORMATION; KEY AGREEMENT TERMS; SIGNATURES:
              </h1>
              <div className="mt-5">
                <div className="flex justify-between">
                  <p>
                    Client 1 Name:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.lead?.firstname} {client?.lead?.lastname}
                    </span>
                  </p>
                  <p>
                    DOB:
                    <span className="text-gray-700 font-bold ml-4">
                      {moment(client?.lead?.dob).format("MM/DD/YYYY") ||
                        "Not provided"}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Client 2 Name:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.lead?.secondary_name || "Not provided"}
                    </span>
                  </p>
                  <p>
                    DOB:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.lead?.secondary_dob
                        ? moment(client?.lead?.secondary_dob).format(
                            "MM/DD/YYYY"
                          )
                        : "Not provided"}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Address:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.lead?.street}, {client?.lead?.city},{" "}
                      {client?.lead?.state}, {client?.lead?.zipCode}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Client Email Address:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.lead?.email}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <table class="w-full sm:bg-white rounded-lg overflow-hidden my-5">
              <tbody class="flex-1 sm:flex-none">
                <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                  <td class="border-grey-light border hover:bg-gray-100 p-3">
                    Initial Term:
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    {client?.lead?.contract_length} Months
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    One Time Fee:
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    ${totalOnetimeFees}
                  </td>
                </tr>
                <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                  <td class="border-grey-light border hover:bg-gray-100 p-3">
                    Monthly Service Rate:
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    ${client?.package?.amount}
                  </td>
                </tr>
                <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                  <td class="border-grey-light border hover:bg-gray-100 p-3">
                    Total Initial Term Payments
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    $
                    {Number(
                      client?.package?.amount * client?.lead?.contract_length
                    ).toFixed(2)}
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    Initial Payment:
                  </td>
                  <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                    $
                    {client?.initial_payment
                      ? Number(client?.initial_payment).toFixed(2)
                      : "0.00"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex items-center">
              <span className="text-red-500 mb-4">
                NB: State and Local taxes applicable (exclusive of original
                amount)
              </span>
            </div>
            <span className=" font-thin text-gray-700 font-bold">
              * The Monthly Service Rate indicated above is subject to increases
              as set forth in Section 20 below.
            </span>
            <p className="text-gray-600 text-justify mt-6">
              <span className=" fw-bolder text-black underline">
                1. INITIAL TERM:
                <br />
                <br />
              </span>{" "}
              Company agrees to provide the Services for the number of months
              shown as the Initial Term in Section 1 above (the “Initial Term”)
              beginning on the Effective Date.
            </p>
            <p className="text-gray-600 text-justify mt-6">
              <span className=" fw-bolder text-black font-bold">
                Electronic Funds Transfer (EFT) and Payment Due Date(s):
              </span>{" "}
              Payment Due Date and Client’s bank account information for
              payments authorized for EFT under the terms of the Agreement are
              provided below. Client verbally provided credit/debit card account
              information for authorized payments, if applicable.
            </p>

            {client?.payment_method?.type === "us_bank_account" && (
              <div className="my-4">
                <div className="flex justify-between">
                  <p>
                    Bank Name:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.payment_method?.us_bank_account?.bank_name}
                    </span>
                  </p>
                  <p>
                    Payment Due Date:
                    <span className="text-gray-700 font-bold ml-4">
                      {moment(today).format("Do")}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Bank Routing #:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.payment_method?.us_bank_account?.routing_number}
                    </span>
                  </p>
                  <div>
                    Bank Acount #:
                    <span className="text-gray-700 font-bold ml-4">
                      ******{client?.payment_method?.us_bank_account?.last4}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {client?.payment_method?.type === "card" && (
              <div className="my-4">
                <div className="flex justify-between">
                  <p>
                    Card Brand:
                    <span className="text-gray-700 font-bold ml-4">
                      {client?.payment_method?.card?.brand}
                    </span>
                  </p>
                  <p>
                    Payment Due Date:
                    <span className="text-gray-700 font-bold ml-4">
                      {moment(today).format("Do")}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Card Number #:
                    <span className="text-gray-700 font-bold ml-4">
                      ************{client?.payment_method?.card?.last4}
                    </span>
                  </p>
                  {/* <div>
                    Bank Acount #:
                    <span className="text-gray-700 font-bold ml-4">203054587895</span>
                  </div> */}
                </div>
              </div>
            )}

            <p className="text-gray-600 text-justify">
              <span className=" fw-bolder text-black font-bold">
                Client Acknowledgment and Signature(s):{" "}
              </span>
              I, the Client, certify that I verified the above information, and
              it is true and correct. I certify that I have received a fully
              executed copy of the Agreement at time of execution which includes
              two copies of the NOTICE OF CANCELLATION form included herein in
              Section 9 below. I acknowledge that I have read and understand the
              entire Agreement and the NOTICE OF CANCELLATION form. <br />{" "}
              <br />
              <span className=" fw-bolder text-black my-6">
                <span className=" underline">BUYER’S RIGHT TO CANCEL:</span>{" "}
                YOU, THE BUYER, MAY CANCEL THIS TRANSACTION AT ANY TIME PRIOR TO
                MIDNIGHT OF THE THIRD BUSINESS DAY AFTER THE DATE OF THIS
                TRANSACTION. SEE THE ATTACHED NOTICE OF CANCELLATION FORM FOR AN
                EXPLANATION OF THIS RIGHT.
              </span>
            </p>
            <p className="text-gray-600 text-justify my-6">
              <span className=" fw-bolder text-black">
                You acknowledge that your Company representative has orally
                explained this right to you.
              </span>
            </p>
            <div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL ? (
                    <img
                      src={imageURL}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={client?.status==="Agreement signed"? null:toggle}
                    />
                  ) : (
                    <div
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle
                      }
                    />
                  )}
                  <p className="underline">Client 1 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL2 ? (
                    <img
                      src={imageURL2}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={ client?.status === "Agreement signed" ? null :toggle2}
                    />
                  ) : (
                    <div
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle2
                      }
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                    />
                  )}
                  <p className="underline">Client 2 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  2. EFFECTIVE DATE AND PARTIES:{" "}
                </span>{" "}
                <br />
                This Agreement is entered into on the Effective Date shown in
                Section 1 above (the “Effective Date”) between Savvy
                Surveillance, LLC (the “Company” or “seller”) and the person(s)
                identified as Client(s) in Section 1 above (the “Client”,
                “buyer”, or “you”), individually each (a “Party”) and
                collectively, (the “Parties”).
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  3. INSTALLATION AND SERVICES:
                </span>{" "}
                <br />
                In accordance with the terms described in the Agreement, Company
                agrees to install equipment at the address identified in Section
                1 above (the “System”) and to maintain said System in working
                order by replacing defective parts and materials and performing
                labor on such with respect to the address above (the
                “Services”). Client is solely responsible for ensuring that the
                Internet connection speed at the address is sufficiently fast to
                allow for full functionality of the System and each of its
                components, particularly the streaming of video feeds from each
                of the System’s remote video cameras, while Client’s other
                wireless devices are in use at the address.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  4. SERVICE CHARGES:
                </span>{" "}
                <br />
                Client agrees to pay each month the monthly service rate shown
                in Section 1 above plus applicable governmental fees and taxes
                (the “Monthly Service Rate”). As set forth in this Agreement,
                Company may impose a late charge equal to the maximum monthly
                interest allowed per Texas state law, on each payment that is
                not paid within ten (10) days of the date due.At the end of the
                initial term", as stated in Section 1 above, Company will reduce
                monthly service charge to $11.99.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black  underline font-bold">
                  5. PAYMENT AUTHORIZATION:
                </span>{" "}
                <br />
                Client authorizes Company or its assignees to make electronic
                funds transfers (EFT) from Client’s bank account provided in
                Section 1 above (i.e. Automated Clearing House (ACH)) and/or
                credit/debit card for any of the account(s) Company is not
                liable in any way for incorrect debits to Client’s account and
                should an error occur, Company’s only responsibility is to
                correct it when and if it receives verbal or written notice of
                the error from Client. Client further agrees that should any
                direct payment be returned or declined, Company may at its
                discretion continue to attempt to process the charge and Client
                shall be liable for a $20.00 return/decline fee for each of
                Company’s attempts to collect which is returned or declined for
                non- sufficient funds which return/decline fee will be initiated
                as a separate transaction from the authorized recurring payment.
                Client authorizes Company, at its sole discretion, as many EFT
                transfer attempts from Client’s bank account, as Company deems
                necessary, each month for the total amount of Monthly Service
                Rate(s) owed by Client during the Term of this Agreement when
                any Monthly Service Rate payment from Client is returned or
                declined. Client agrees not to dispute this recurring billing
                with Client’s bank or Credit Card Company so long as the
                transactions correspond to the terms indicated in this
                Agreement. Client acknowledges and agrees that Client shall be
                responsible for all overdraft charges or other charges imposed
                by Client’s financial institution or credit or debit card
                company in connection with Company’s attempts to obtain payment
                of amounts due under this Agreement. Client further authorizes
                Company to use all payment methods provided by Client to be used
                for the collection of payments due hereunder.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black  underline font-bold">
                  6. SERVICE REPAIRS TO CLIENT’S SYSTEM:
                </span>{" "}
                <br />
                Client understands that the Monthly Service Rate covers
                replacement of defective parts, materials and workmanship,
                including labor as to maintain such System in working order
                (“System Repair). So long as Company is providing Services
                pursuant to this Agreement, Company shall repair or replace any
                defective part of the System without charge to Client. Client
                hereby agrees to periodically test/view the System, at least
                monthly, observe Client’s System, be aware of its operational
                status in accordance with the operating instructions, and
                request System Repair if needed. To request a System Repair
                contact Company at (832)224-6816, or{" "}
                <span className="text-blue-500">support@savvycams.com</span>
              </p>
            </div>
            <div className="my-4">
              <span className=" fw-bolder text-black underline font-bold">
                7. NOTICE OF CANCELLATION:
              </span>{" "}
              <table
                className="border table-auto border-black mt-6"
                border={"1"}
              >
                <tr>
                  <td className="border border-black">
                    Date Of Transaction : <br />
                    {client?.createdAt
                      ? moment(client?.createdAt).format("MM/DD/YYYY")
                      : moment().format("MM/DD/YYYY")}
                  </td>
                  <td className="border border-black font-bold p-6 bg-gray-300">
                    NOTICE OF CANCELLATION
                  </td>
                  <td className="border border-black col-span-2 sm:col-span-1"></td>
                </tr>
                <tr>
                  <td colSpan={3} className="border border-black p-4">
                    YOU MAY CANCEL THIS TRANSACTION, WITHOUT ANY PENALTY OR
                    OBLIGATION, WITHIN THREE BUSINESS DAYS FROM THE ABOVE DATE.
                    IF YOU CANCEL, ANY PROPERTY TRADED IN, ANY PAYMENTS MADE BY
                    YOU UNDER THE CONTRACT OR SALE, AND ANY NEGOTIABLE
                    INSTRUMENT EXECUTED BY YOU WILL BE RETURNED WITHIN TEN
                    BUSINESS DAYS FOLLOWING RECEIPT BY THE SELLER OF YOUR
                    CANCELLATION NOTICE, AND ANY SECURITY INTEREST ARISING OUT
                    OF THE TRANSACTION WILL BE CANCELLED. IF YOU CANCEL, YOU
                    MUST MAKE AVAILABLE TO THE SELLER AT YOUR RESIDENCE, IN
                    SUBSTANTIALLY AS GOOD CONDITION AS WHEN RECEIVED, ANY GOODS
                    DELIVERED TO YOU UNDER THIS CONTRACT OR SALE, OR YOU MAY, IF
                    YOU WISH, COMPLY WITH THE INSTRUCTIONS OF THE SELLER
                    REGARDING THE RETURN SHIPMENT OF THE GOODS AT THE SELLER’S
                    EXPENSE AND RISK. IF YOU DO MAKE THE GOODS AVAILABLE TO THE
                    SELLER AND THE SELLER DOES NOT PICK THEM UP WITHIN 20 DAYS
                    OF THE DATE OF YOUR NOTICE OF CANCELLATION, YOU MAY RETAIN
                    OR DISPOSE OF THE GOODS WITHOUT ANY FURTHER OBLIGATION. IF
                    YOU FAIL TO MAKE THE GOODS AVAILABLE TO THE SELLER, OR IF
                    YOU AGREE TO RETURN THE GOODS TO THE SELLER AND FAIL TO DO
                    SO, THEN YOU REMAIN LIABLE FOR PERFORMANCE OF ALL
                    OBLIGATIONS UNDER THE CONTRACT. TO CANCEL THIS TRANSACTION,
                    MAIL A SIGNED AND DATED COPY OF THIS CANCELLATION NOTICE OR
                    ANY OTHER WRITTEN NOTICE, TO{" "}
                    <span className="text-blue-500">
                      PO BOX 681 Simonton, TX 77476 or support@savvycams.com
                    </span>{" "}
                    NOT LATER THAN MIDNIGHT OF
                    <br />
                    <p className="mt-6 text-blue-800 italic">
                      {cancel_before
                        ? moment(cancel_before).format("MMM Do, YYYY")
                        : moment(threeBusinessWorkingDays()[2]).format(
                            "MMM Do, YYYY"
                          )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-3">
                    I HEARBY CANCEL THIS TRANSACTION
                  </td>
                  <td className="border border-black p-6 col-span-2 sm:col-span-1">
                    Name:
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-3">
                    Date: <br />
                    {/* <input type="date" /> */}
                    __ /___/____
                  </td>
                  <td className="border border-black p-6 col-span-2 sm:col-span-1">
                    Buyer's Signature:
                  </td>
                </tr>
              </table>
              <table
                className="border table-auto border-black mt-6"
                border={"1"}
              >
                <tr>
                  <td className="border border-black">
                    Date Of Transaction: <br />
                    {client?.createdAt
                      ? moment(client?.createdAt).format("MM/DD/YYYY")
                      : moment().format("MM/DD/YYYY")}
                  </td>
                  <td className="border border-black font-bold p-6 bg-gray-300">
                    NOTICE OF CANCELLATION
                  </td>
                  <td className="border border-black col-span-2 sm:col-span-1"></td>
                </tr>
                <tr>
                  <td colSpan={3} className="border border-black p-4">
                    YOU MAY CANCEL THIS TRANSACTION, WITHOUT ANY PENALTY OR
                    OBLIGATION, WITHIN THREE BUSINESS DAYS FROM THE ABOVE DATE.
                    IF YOU CANCEL, ANY PROPERTY TRADED IN, ANY PAYMENTS MADE BY
                    YOU UNDER THE CONTRACT OR SALE, AND ANY NEGOTIABLE
                    INSTRUMENT EXECUTED BY YOU WILL BE RETURNED WITHIN TEN
                    BUSINESS DAYS FOLLOWING RECEIPT BY THE SELLER OF YOUR
                    CANCELLATION NOTICE, AND ANY SECURITY INTEREST ARISING OUT
                    OF THE TRANSACTION WILL BE CANCELLED. IF YOU CANCEL, YOU
                    MUST MAKE AVAILABLE TO THE SELLER AT YOUR RESIDENCE, IN
                    SUBSTANTIALLY AS GOOD CONDITION AS WHEN RECEIVED, ANY GOODS
                    DELIVERED TO YOU UNDER THIS CONTRACT OR SALE, OR YOU MAY, IF
                    YOU WISH, COMPLY WITH THE INSTRUCTIONS OF THE SELLER
                    REGARDING THE RETURN SHIPMENT OF THE GOODS AT THE SELLER’S
                    EXPENSE AND RISK. IF YOU DO MAKE THE GOODS AVAILABLE TO THE
                    SELLER AND THE SELLER DOES NOT PICK THEM UP WITHIN 20 DAYS
                    OF THE DATE OF YOUR NOTICE OF CANCELLATION, YOU MAY RETAIN
                    OR DISPOSE OF THE GOODS WITHOUT ANY FURTHER OBLIGATION. IF
                    YOU FAIL TO MAKE THE GOODS AVAILABLE TO THE SELLER, OR IF
                    YOU AGREE TO RETURN THE GOODS TO THE SELLER AND FAIL TO DO
                    SO, THEN YOU REMAIN LIABLE FOR PERFORMANCE OF ALL
                    OBLIGATIONS UNDER THE CONTRACT. TO CANCEL THIS TRANSACTION,
                    MAIL A SIGNED AND DATED COPY OF THIS CANCELLATION NOTICE OR
                    ANY OTHER WRITTEN NOTICE, TO{" "}
                    <span className="text-blue-500">
                      PO BOX 681 Simonton, TX 77476 or support@savvycams.com
                    </span>{" "}
                    NOT LATER THAN MIDNIGHT OF
                    <br />
                    <p className="mt-6 text-blue-800 italic">
                      {cancel_before
                        ? moment(cancel_before).format("MMM Do, YYYY")
                        : moment(threeBusinessWorkingDays()[2]).format(
                            "MMM Do, YYYY"
                          )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-3">
                    I HEARBY CANCEL THIS TRANSACTION
                  </td>
                  <td className="border border-black p-6 col-span-2 sm:col-span-1">
                    Name:
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-3">
                    Date: <br />
                    __ /___/____
                  </td>
                  <td className="border border-black p-6 col-span-2 sm:col-span-1">
                    Buyer's Signature:
                  </td>
                </tr>
              </table>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  8. LIMITED WARRANTY:
                </span>{" "}
                <br />
                In exchange for Client’s payment of the Monthly Service Rate as
                required under this Agreement, Company shall maintain the System
                in working order during the Term hereof. Company makes no
                guaranty or warranty, including any implied warranty of
                merchantability or fitness for a particular purpose that the
                System or Services supplied will avert or prevent occurrences
                arising therefrom. Client acknowledges that Company does not
                represent or warrant that the System may not be compromised or
                circumvented, including by hacking or otherwise. Company shall
                promptly replace any inoperative equipment with operative
                equipment, or make all adjustments, repairs, and parts
                replacements required to maintain the equipment rented or
                supplied hereunder in working condition. Company may use
                reconditioned parts in making repairs, but company warrants the
                replacement parts only for the remainder of the warranty period.
                Notwithstanding the preceding, Company shall not repair or
                replace batteries or any part of the System that is defective
                due to acts of God, any casualty, vandalism, fire, lightning,
                flood, water, intrusion, electrical surges, Client negligence,
                alterations or attempted unauthorized repair service not
                performed by Company, misuse, tampering, abuse, accidents not
                caused by Company, improper installation by anyone other than
                (a) Company, (b) Company’s authorized representatives or (c)
                Client in accordance with instructions provided by Company, or
                any other cause other than ordinary wear and tear. Company shall
                not be liable for any general, direct, special, exemplary,
                punitive, incidental, or consequential damages caused by breach
                of expressed or implied warranty. Except as specifically set
                forth in this Section 8 of the Agreement, Company shall not be
                obligated to provide service of any type to the System for the
                benefit of Client. At Company’s option, a fee may be charged for
                unnecessary service required by Client’s actions.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  9. CLIENT INFORMATION.
                </span>{" "}
                <br />
                All Client information and data shall be maintained
                confidentially by Company.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  10. CONTACT INFORMATION:
                </span>{" "}
                <br />
                With respect to any phone number or other contact information
                Client provides to Company at any time, whether for purposes of
                general billing, or any other purpose, Client represents that
                Client is the subscriber associated with the phone number, email
                address or other contact information provided, or that Client is
                otherwise expressly authorized by the person or persons
                associated with such phone number, email address or other
                contact information to provide such information and use it as
                Client’s own. Client also expressly authorizes Company to
                contact Client using an automated calling device, text, or email
                at such phone number, email address or other contact
                information, which Client acknowledges and agrees shall be
                provided for all purposes related to servicing or administering
                Client’s account with Company, including billing, under this
                Agreement.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  11. CLIENT’S RESPONSIBILITIES:
                </span>{" "}
                <br />
                Client acknowledges that to maintain the System in good working
                order requires the cooperation and implementation of certain
                safeguards by the Client. As may be further referenced in this
                Agreement, Client agrees to (i) read all operations/user’s
                manuals for the System, and to operate and maintain the System
                according to the manufacturer’s instructions; (ii) view the
                System as recommended by the manufacturer, or at a minimum
                monthly, and to notify Company immediately if Client finds
                anything wrong; (iii) replace batteries in the System components
                as necessary; (iv) not tamper, disturb, injure, remove, or
                interfere with the System or allow anyone else to do so; (v)
                keep the System and its components in the same location as
                originally installed by Company; (vi) avoid doing anything that
                might damage the System; (vii) keep Client’s information updated
                by notifying the Company of any changes.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  12. REMOTE SERVICES ACCESS / EXCESSIVE DATA USAGE / AUDIO &
                  VIDEO LIMITATIONS:
                </span>{" "}
                <br />
                If any package or equipment that provides remote access and or
                remote viewing is designed to transmit data via Client’s high-
                speed Internet to remote equipment supplied by Company or
                Client’s Internet or wireless connection device which is
                compatible with Company’s app. Company shall have no
                responsibility for failure of data transmission, corruption or
                unauthorized access to the camera data and shall not monitor or
                view said data. Company shall have no liability for excessive
                data usage expense incurred by Client attributable to the
                equipment or services provided herein. Client acknowledges and
                understands that electronic data may not be encrypted and
                wireless components of the System, including camera data, may
                not meet Advanced Encryption Standard specifications for
                encryption of electronic data established by the US National
                Institute of Standards and Technology and Company shall have no
                liability for access to the System, including camera data, by
                others. Client acknowledges that Client has been advised to
                independently ascertain that the audio or video devices are used
                lawfully. Company has made no representations and has provided
                no advice regarding the use of audio or video devices.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  13. COMPANY NOT AN INSURER; LIQUIDATED DAMAGES; LIMITATION OF
                  LIABILITY:
                </span>{" "}
                <br />
                It is understood and agreed by Client that Company is not an
                insurer. Insurance, if any, shall be obtained by Client. The
                payments required hereunder are based solely upon the value of
                the Services and are unrelated to the value of Client’s property
                or the property of others located on Client’s premises. Client
                acknowledges that it is impractical and extremely difficult to
                fix the actual damages, if any, which may proximately result
                from the failure of the System to properly operate. Client
                understands and agrees that if Company or its affiliates and
                contractors should be found liable for any loss, injury or
                damage due to the failure of Company to perform any of the
                obligations herein, including, but not limited to installation,
                maintenance or service or the failure of the System or Services
                in any respect whatsoever.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  14. NO COMMUNICATION WITH AUTHORITIES:
                </span>{" "}
                <br />
                Client understands that the System is designed for surveillance
                purposes only and not for the intent of preventing, detecting,
                deterring any act of harm to the property or persons located at
                the address. Further, in no way is the System intended to
                notify, signal, or in any other way communicate to any authority
                (police, fire, ambulance). Client acknowledges that Company does
                not represent or warrant that the System will prevent any loss
                by burglary, theft, robbery, fire, or otherwise.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  15. INTERRUPTION OF SERVICES:
                </span>{" "}
                <br />
                Company assumes no liability for delays in installation or
                interruption of the Services due to strikes, riots, floods,
                fires, acts of God or any causes beyond the control of Company.
                Company will not be required to supply Services to Client during
                the period of interruption of the Services due to any such
                cause. Company makes no promise that there will be no delay in
                performing Repair Service. Company’s sole obligation after
                receiving a Repair Service request from Client is to
                troubleshoot by phone and remote access and, if unsuccessful in
                troubleshooting, dispatch a service technician to the address
                within a reasonable time after a service technician becomes
                available, during normal business hours excluding Saturdays,
                Sundays and holidays.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  16. THIRD-PARTY INDEMNIFICATION:
                </span>{" "}
                <br />
                This Agreement is intended only for Client’s benefit. The
                Parties agree that there are no third-party beneficiaries of
                this Agreement. Therefore, Client agrees to protect/indemnify,
                defend and release Company and Company Persons from liability
                against all third-party claims or losses (including reasonable
                attorneys’ fees) brought against Company or Company Persons
                arising out of or relating in any way to this Agreement, the
                System, or Services the Company provides to Client. This
                protection/indemnity covers claims brought against Company or
                Company Persons by, among others, Client’s insurance company. It
                also covers claims arising under contract, warranty, negligence,
                or any other theory of liability. In addition, in case of any
                third-party claim or loss covered by Client’s insurance, Client
                agrees not to look to Company or Company Persons for
                reimbursement. Client waives any rights that Client’s insurance
                carrier, or others claiming through Client, may have against
                Company or Company Persons. If anyone other than Client asks
                Company to pay for any harm or damages (including property
                damage, personal injury or death) connected with or resulting
                from (i) Company’s breach of this Agreement, (ii) a failure of
                the System or Services, (iii) Company’s or Company Persons’
                negligence (including gross negligence), (iv) any other improper
                or careless activity of Company or Company Persons in providing
                the System or Services or (v) a claim for indemnification or
                contribution, Client agrees to pay Company (a) any amount which
                a court orders Company or any Company Persons to pay or which
                Company or Company Persons reasonably agree to pay, and (b) the
                amount of Company’s or Company Persons’ reasonable attorneys’
                fees and any other losses and costs that Company or Company
                Persons may pay in connection with the alleged harm, injury, or
                damages.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  17. SUBROGATION:
                </span>{" "}
                <br />
                Client hereby releases, discharges and agrees to hold Company
                harmless from any and all claims, liabilities, damages, losses
                or expenses arising from or caused by any hazard covered by
                insurance in or on the premises of Client whether said claim is
                made by Client, his/her agents, or insurance company or by any
                other parties claiming under or through Client. Client agrees to
                indemnify Company and Company Persons against and defend and
                hold Company and Company Persons harmless from, any action for
                subrogation which may be brought against Company or Company
                Persons by an insurer, insurance company, or their respective
                agents or assigns, including the payment of all damages,
                expenses, costs and attorney’s fees.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  18. CREDIT CHECK:{" "}
                </span>{" "}
                <br />
                In compliance with the Fair Credit Reporting Act (FCRA), Client
                agrees and acknowledges that it is authorizing Company to obtain
                a consumer credit report in conjunction with the execution of
                this Agreement during the Initial Term for valid business
                purposes of Company and to report Client’s payment performance
                under this Agreement to credit agencies and credit reporting
                services. Client has the right, by contacting the provider of
                the information, to dispute the information on the report and
                request additional disclosures as provided under the FCRA and a
                written summary of Client’s rights. Please call the proper
                credit- reporting agency for assistance with an inquiry, as
                Company is not allowed to give the Client the credit report.
                Client releases any person involved in the credit investigation
                from liability in connection with such investigation.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  19. ASSIGNEES / SUBCONTRACTORS OF COMPANY:{" "}
                </span>{" "}
                <br />
                Company shall have the right to assign this Agreement to any
                other person, firm or corporation without notice to Client and
                upon such assignment shall have no further obligation hereunder.
                Company shall have a further right to subcontract any
                installation of the System and/or performance of the Services.
                Client acknowledges that this Agreement, and particularly those
                Sections hereof relating to Company’s disclaimer of warranties,
                maximum liability, limitation of liability, and third-party
                indemnification limitation on lawsuits, venue, and waiver of
                jury trial, inure to the benefit of, are applicable to, and
                fully enforceable by, any assignees and/or subcontractors of
                Company, and these Sections bind Client with respect to said
                assignees and/or subcontractors with the same force and effect
                the Sections bind Client to Company.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  20. INCREASE IN MONTHLY SERVICE RATE:
                </span>{" "}
                <br />
                Client acknowledges that the Monthly Service Rate is based upon
                existing federal, state and local taxes and other third-party
                charges. Company shall have the right, at any time, to increase
                the Monthly Service Rate to reflect any additional or increased
                taxes, licenses, permits, or fees which Company may incur, or
                which may be charged to Company by any utility or governmental
                agency relating to the Services Company provides and Client
                agrees to pay the same.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  21. INSTALLATION OF THE SYSTEM:
                </span>{" "}
                <br />
                Client represents and warrants to Company that Client has full
                authority from the owner and/or other person in control of the
                premises to permit the installation and operation of the System.
                Client acknowledges that Client has approved the location of
                where the System will be installed in the premises. Client
                acknowledges and agrees that Client has an affirmative duty to
                inform Company, prior to beginning installation, of every
                location at the premises where Company should not (because of
                concealed obstructions or hazards such as pipes, wires, or
                asbestos) enter or drill holes. Unless so notified, Company will
                determine where to drill holes and place equipment. Company will
                take reasonable precautions to avoid concealed obstruction but
                has no means of determining with certainty if they exist. Client
                agrees that any cost to repair pipes, wires or other
                obstructions, and any resulting damaged walls, ceilings, floors
                or furnishings which result from Company’s installation of the
                System, or any part or component thereof shall be Client’s sole
                expense and responsibility. If asbestos or other health
                hazardous material is encountered during installation, Company
                will cease work until Client has, at Client’s sole expense,
                obtained clearance from a licensed asbestos removal or hazardous
                material contractor that the continuation of work will not pose
                any danger to Company’s personnel. In no case shall Company be
                liable for discovery or exposure of hidden asbestos or other
                hazardous material. After Company completes the installation of
                the System, Client agrees that Client shall inspect the System.
                If any items or components of the System are missing or not
                properly installed, Client will advise Company within ten (10)
                days of the System’s installation, otherwise the System shall be
                deemed accepted by and satisfactory to Client. Client
                acknowledges and agrees that Company shall be in no way
                obligated to repair, restore, or redecorate the premises in the
                event the System is removed from the premises for any reason.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  22. CHANGES TO THE SYSTEM OR SERVICES:
                </span>{" "}
                <br />
                If Client or any governmental agency or insurance interest wants
                Company to change the System or Services described herein, or
                change the System after it is installed, Client agrees to pay
                Company’s standard parts and labor charges for such changes.
                Client agrees that Client has chosen the System and Services and
                Client understands that additional equipment and services may be
                available for a higher price. If at any time after the Effective
                Date, Client requests or authorizes changes to the System or
                Services, all sales, installation, and services supplied by
                Company shall be subject to the terms of this Agreement only,
                except that Client shall pay all applicable additional charges
                for such sales, installation and/or services. Client agrees that
                this Agreement is applicable in its entirety to any changes,
                upgrades or additions to the System or Services.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  23. LIMITATION ON LAWSUITS; VENUE; WAIVER OF JURY TRIAL:
                </span>{" "}
                <br />
                Unless prohibited by applicable law, Company and Client agree
                that no lawsuit, claim, action, or any other legal proceeding
                (each, an “Action”) connected with this Agreement shall be
                brought or filed more than one (1) year after the incident
                giving rise to the claim occurred. Company and Client hereby
                irrevocably agree that any Action relating to, arising out of or
                from, in connection with, or as a result of this Agreement shall
                be brought exclusively in the County where the System is
                installed. Company and Client each consent to the exclusive
                jurisdiction and venue of such courts in any such Action and
                waives any objection that either Company or Client may have to
                jurisdiction or venue of said court. Unless prohibited by law,
                any Action brought by either Party with respect to this
                Agreement shall not be heard before a jury. Client acknowledges
                and represents that Client’s waiver of the right to a jury trial
                is done voluntarily and knowingly, and free from duress or
                coercion. Client understands that Client has a right to consult
                with a person of Client’s choosing, including an attorney,
                before signing this Agreement. The provisions of this Section
                29, which expressly limit the venue and jurisdiction of any and
                all Actions relating to or arising from or having any connection
                with this Agreement, shall remain in full force and effect even
                after this Agreement expires or is terminated for any reason.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  24. EARLY CANCELLATION:
                </span>{" "}
                <br />
                Client understands and agrees that the charges due under this
                Agreement are based on Client’s agreement to receive and pay for
                Services for the full Initial Term of this Agreement and that
                Company has relied upon Client’s agreement and has incurred
                costs in deciding to enter into this Agreement. If Client
                cancels Services or otherwise terminates this Agreement during
                the Initial Term or if Company cancels this Agreement as a
                result of Client’s default, Client agrees to pay Company 90% of
                the total remaining monthly charges otherwise due under this
                Agreement.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  25. COMPANY’S RIGHT TO TERMINATE:
                </span>{" "}
                <br />
                Company may, at any time, cancel this Agreement at its option
                if: (i) Client fails to follow Company’s operating instructions
                for the System; or (ii) Company determines that it is
                impractical to continue the Services due to the modification or
                alteration of Client’s premises after installation. If Company
                cancels for any of the reasons stated immediately above, Company
                will refund any advance payments made for the Services to be
                supplied after the date of such termination, less any amounts
                still due for the installation of the System, for the Services
                already rendered, and for any other charges due. Notwithstanding
                anything contained herein to the contrary, Company may, in its
                sole discretion, terminate this Agreement, with or without cause
                and without any liability whatsoever, upon thirty (30) days’
                notice to Client. In the event such termination is without
                cause, Company shall, upon the written request of Client, refund
                any advance payments made for the Services to be supplied after
                the date of such termination, less any amounts still due for the
                installation of the System, for the Services already rendered
                and for any other charges due.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  26. SEVERABILITY:
                </span>{" "}
                <br />
                The invalidity of any one or more of the words, phrases,
                sentences, clauses, or sections contained in this Agreement
                shall not affect the enforceability of the remaining portions of
                this Agreement or any part hereof.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  27. CREDIT AGENCY REPORTING:{" "}
                </span>{" "}
                <br />
                Client acknowledges and agrees that in the event Client fails to
                pay any amounts owing under this Agreement within the time
                frames set forth herein, Company may, at its sole discretion,
                report any such payment default to one or more credit reporting
                agencies.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  28. CLASS ACTION WAIVER: THE PARTIES EACH AGREE THAT ANY
                  PROCEEDINGS, WHETHER IN ARBITRATION OR COURT, WILL BE
                  CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS OR
                  REPRESENTATIVE CAPACITY OR AS A MEMBER IN A CLASS,
                  CONSOLIDATED OR REPRESENTATIVE ACTION. FURTHERMORE, CLAIMS
                  BROUGHT BY OR AGAINST COMPANY MAY NOT BE JOINED OR
                  CONSOLIDATED IN THE ARBITRATION OR OTHER PROCEEDING WITH
                  CLAIMS BROUGHT BY OR AGAINST ANY OTHER COMPANY CLIENT, UNLESS
                  OTHERWISE AGREED BY THE PARTIES.
                </span>{" "}
                <br />
                Neither Client, nor any other client of Company, can be a class
                representative, class member, or otherwise participate in a
                class, consolidated or representative proceeding without having
                complied with the opt out requirements above.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  29. RIGHTS IN DATA:
                </span>{" "}
                <br />
                Client agrees to permit Company to track and retain personal
                information, demographic information, equipment statistics and
                related information for Company’s internal marketing,
                demographic, and other purposes, so Company can improve,
                personalize, and customize the products and services Company
                provides to Client and to Company’s other clients and to better
                meet Client’s needs.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  30. SECTION HEADINGS:{" "}
                </span>{" "}
                <br />
                The section titles used herein are for convenience of the
                parties only and shall not be considered in construing the
                provisions of the Agreement.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  31. AUTHORIZATION FOR COMPANY TO CONTACT CLIENT:
                </span>{" "}
                <br />
                Client expressly authorizes Company and agrees to receive
                pre-recorded/artificial voice messages calls and/or use of an
                automatic dialing device, text messages and/or emails from
                Company, its partners, contractors, or any and all other
                companies that Company may have to transfer Client’s account to
                any telephone number or email address that Client has provided
                to Company, which could result in charges to Client. Company may
                place such calls (whether manually or through the use of an
                automatic dialing device), texts or emails to (i) troubleshoot
                problems with Client’s account or System; (ii) resolve a
                dispute; (iii) collect a debt Client owes to Company, or any
                third-party providing Services to Client; or (iv) as otherwise
                necessary to service Client’s account or enforce this Agreement,
                Company’s policies, applicable law, or any other terms contained
                herein. The ways in which Client may provide Company a telephone
                or mobile number or email address include, but are not limited
                to, (1) providing the information to Company at the time Client
                enters into this Agreement with Company; (2) by adding the
                information to Client’s account at a later time; (3) providing
                it to one of Company’s representatives; (4) providing it to
                Company’s affiliates, contractors, or any and all other
                companies to which Company may have to transfer Client’s account
                to; or (5) by contacting Company or its affiliates, contractors,
                or any and all other companies that Company may transfer
                Client’s account from that phone number or email address. If a
                telephone number provided to Company is a mobile telephone
                number, Client consents to receive calls (whether manually or
                through the use of an automatic dialing device) and SMS or text
                messages at that number. Standard telephone minute and text
                charges may apply if Company contacts Client. Client
                acknowledges that Client’s authorization and consent for Company
                to contact Client via phone or email is given as bargained-for
                consideration of this Agreement, and the Company’s providing of
                its services and support of the System, and as such cannot be
                revoked by Client without the mutual consent or agreement of
                Company. Client further agrees that all consents provided in
                this section will survive termination or expiration of this
                Agreement.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  32. ENTIRE AGREEMENT:
                </span>{" "}
                <br />
                The Parties represent and acknowledge that this Agreement,
                entered into concurrently herewith, constitute the entire
                agreement by the Parties relating to the subject matter hereof
                and there are no written changes or verbal understandings that
                conflict with, change, or modify any of the terms of this
                Agreement unless agreed to by both Parties on a recorded call or
                in writing on a separate rider signed by both Company and
                Client. This Agreement may not be changed, modified or varied
                except in writing and signed by an authorized representative of
                the Company, or agreed to verbally by both Parties on a recorded
                call. CLIENT HEREBY ACKNOWLEDGES THAT HE/SHE HAS READ, RECEIVED
                A COPY OF, AND UNDERSTANDS THIS ENTIRE AGREEMENT, INCLUDING THE
                NOTICE OF CANCELLATION FORM. Client agrees that this Agreement
                and the signatures affixed hereto may be transmitted, saved,
                stored and delivered by facsimile or electronically and that all
                such signatures and this Agreement transmitted, saved, stored or
                delivered by facsimile or electronically shall be deemed to be
                exclusive originals for all purposes and given the same legal
                force and effect as the original Agreement and signatures.
              </p>
            </div>
            <div className="my-4">
              <p className="text-black text-justify mb-6">
                <span className=" fw-bolder  underline font-bold">
                  33. CLIENT ACKNOWLEDGMENT:{" "}
                </span>{" "}
                <br />
                BY SIGNING BELOW, CLIENT SPECIFICALLY ACKNOWLEDGES THAT CLIENT
                HAS READ AND ACCEPTS THE FOLLOWING CRITICAL PROVISIONS OF THIS
                AGREEMENT, AS SET FORTH MORE FULLY IN THE FOREGOING SECTIONS:
              </p>
              <ul className="list-disc text-gray-500">
                <li className="text-justify">
                  THAT COMPANY IS NOT AN INSURER, AND CLIENT SHOULD SEPARATELY
                  OBTAIN WHATEVER INSURANCE CLIENT DEEMS APPROPRIATE TO PROTECT
                  THE PERSONS AND PROPERTY AT THE PREMISES (See Section 13);
                </li>
                <li className="text-justify">
                  THAT CLIENT WILL INDEMNIFY COMPANY AGAINST ANY THIRD-PARTY
                  CLAIM ARISING FROM OR RELATING TO THIS AGREEMENT OR THE
                  RELATIONSHIP CREATED HEREUNDER (See Section 16);
                </li>
                <li className="text-justify">
                  THAT IN THE EVENT CLIENT ATTEMPTS TO TERMINATE THIS AGREEMENT
                  EARLY, CLIENT WILL PAY ALL MONEYS THEN DUE PLUS 90% OF ALL
                  MONEYS DUE TO COMPANY FOR THE REMAINING TERM OF THE AGREEMENT
                  (See Section 24);
                </li>
                <li className="text-justify">
                  THAT, EXCEPT AS SET FORTH IN THIS AGREEMENT, COMPANY MAKES NO
                  OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED
                  WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE (See Sections 8);
                </li>
                <li className="text-justify">
                  THAT, EXCEPT AS SET FORTH IN THIS AGREEMENT, ANY DISPUTES
                  BETWEEN THE PARTIES WILL BE SUBJECT TO ARBITRATION OR SMALL
                  CLAIMS COURT AND WILL BE BROUGHT IN THE PARTIES’ INDIVIDUAL
                  CAPACITY, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                  CLASS OR REPRESENTATIVE PROCEEDING (See Section 28);
                </li>
                <li className="text-justify">
                  THAT CLIENT HAS RECEIVED A COPY OF THIS AGREEMENT AND READ ALL
                  PAGES BEFORE SIGNING.
                </li>
              </ul>
            </div>
            <div className="my-4">
              <p className="text-black text-justify my-6">
                <span className=" fw-bolder text-black">
                  34. CLIENT ALSO ACKNOWLEDGES AND CONFIRMS THAT PRIOR TO
                  SIGNING THIS AGREEMENT, COMPANY EXPLICITLY POINTED OUT EACH OF
                  THE FOLLOWING TERMS AND PROVISIONS OF THIS AGREEMENT, AND THAT
                  SUCH TERMS AND CONDITIONS WERE CLEARLY AND CONSPICUOUSLY
                  IDENTIFIED IN THIS AGREEMENT AND EITHER GROUPED TOGETHER OR
                  SEGREGATED FROM ALL OTHER TERMS AND CONDITIONS OF THIS
                  AGREEMENT (AND CONTAINED NO INFORMATION UNRELATED TO) THE
                  FOLLOWING:
                </span>{" "}
              </p>
              <ul className="list-disc text-gray-500">
                <li className="text-justify">
                  CLIENT’S PAYMENT SCHEDULE, INCLUDING THAT THE AMOUNT(S) AND
                  TIMING OF EACH PAYMENT REQUIRED TO BE PAID UNDER THIS
                  AGREEMENT (See Section 1);
                </li>
                <li className="text-justify">
                  THE TOTAL NUMBER OF PAYMENTS REQUIRED TO BE PAID UNDER THIS
                  AGREEMENT (See Section 1);
                </li>
              </ul>
            </div>
            <div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL ? (
                    <img
                      src={imageURL}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={client?.status==="Agreement signed"? null:toggle}
                    />
                  ) : (
                    <div
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle
                      }
                    />
                  )}
                  <p className="underline">Client 1 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL2 ? (
                    <img
                      src={imageURL2}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={ client?.status === "Agreement signed" ? null :toggle2}
                    />
                  ) : (
                    <div
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle2
                      }
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                    />
                  )}
                  <p className="underline">Client 2 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="underline text-xl my-16">
              CONSUMER DISCLOSURE AND CONSENT TO ELECTRONIC SIGNATURES AND
              COMMUNICATIONS
              <p className="text-center">(“eSign Consent”)</p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  1. DISCLOSURE.
                </span>{" "}
                <br />
                Federal law [Electronic Signatures in Global and National
                Commerce Act, 15 U.S.C. §§ 7001-7031] and State law permits you
                to agree to the receipt and access of certain documents and
                communications in electronic form, notwithstanding any
                requirement for written copies. Although these laws allow you to
                consent to and receive copies of Documents and Communications
                (as defined in Section 2 below) electronically, you have the
                right to choose whether to sign and receive Documents and
                Communications electronically or to receive paper copies of all
                Documents and Communication. If you elect to receive Documents
                and Communications electronically from Savvy Surveillance, LLC
                (hereinafter referred to as “Company”), then by signing this
                consent form (this “eSign Consent”) you agree and consent to
                receive all Documents and Communications in an electronic format
                subject to the terms and conditions set forth herein, without
                waiving your rights to require you be provided a paper copy of
                any Documents and Communications. As explained below in Section
                4, you have the right to withdraw this eSign Consent at any
                time, and pursuant to Section 4 below you may require (i) that
                you be provided a paper copy of any Document or Communication at
                the time you sign it, or at any time thereafter, by making a
                request that you be given a paper copy and (ii) to begin
                receiving paper copies of all documents and communications going
                forward upon revoking this eSign Consent. Please save or print a
                copy of this eSign Consent for your records.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  2. RIGHT OF CANCELLATION.{" "}
                </span>{" "}
                <br />
                Subject to your rights set forth in Section 3 below to revoke
                the following consent, and provided you choose to receive all
                Documents and Communications electronically, you agree to
                receive all documents and communications from the Company in an
                electronic format (“Electronic Communications”), including, but
                not limited to, agreements, terms and conditions, bills for
                services, correspondence, and disclosures and statutory notices
                required by law to be in writing, such as your right to cancel
                any agreement you sign electronically with the Company at any
                time and for any reason before midnight of the third (3rd)
                business day after you sign any agreement electronically (the
                “Cancellation Period”) along with two (2) copies of the Notice
                of Cancellation form which is to be completed by you and sent to
                the Company to cancel your agreement within the Cancellation
                Period (“Documents and Communications”).
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  3. CONSENT TO ELECTRONIC DELIVERY.
                </span>{" "}
                <br />
                Subject to the provisions of Section 4 below, You accept any
                Electronic Communications provided by Company as reasonable and
                proper notice in full satisfaction of any law or regulation
                requiring that such Documents and Communications be provided to
                you in writing, or in a form that you have the ability to
                retain. Should you wish to update any of your personal account
                and contact information once you have reviewed any Electronic
                Communication, you are free to make such changes by contacting
                the Company in writing to Company at support@savvycams.com or
                to, Savvy Surveillance LLC, PO BOX 681 Simonton, TX 77476. You
                agree that you are solely responsible for notifying Company of
                any changes to your Email Address, and agree to keep it up to
                date by writing to Company at support@savvycams.com or to Savvy
                Surveillance, LLC at PO BOX 681 Simonton, TX 77476.. The Company
                will provide to you all Electronic Communications using your
                following email address:
                <br />
                <span className="underline text-blue-600">
                  {client?.lead?.email}
                </span>
                (your "Email Address")
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  4. PAPER COPIES OF ELECTRONIC COMMUNICATIONS.
                </span>{" "}
                <br />
                You agree that the Company does not need to provide you with
                additional paper (non-electronic) copies of any Electronic
                Communications unless you specifically request to receive paper
                copies. You may request a paper copy of any Electronic
                Communications provided by the Company. To submit a request to
                receive a paper copy of all Electronic Communications contact
                Company by writing to Company at
                <span className="underline text-blue-600">
                  support@savvycams.com or to Savvy Surveillance, LLC at PO BOX
                  681 Simonton, TX 77476.
                </span>
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  5. REVOCATION OF CONSENT TO ELECTRONIC COMMUNICATIONS.
                </span>{" "}
                <br />
                You have the right to withdraw your consent to receive
                Electronic Communications from the Company at any time. You
                acknowledge that the Company reserves the right to restrict or
                terminate your access to its website, web portal or web
                application if you withdraw your consent to receive Electronic
                Communications. To withdraw your consent to receive Electronic
                Communications contact Company by writing to Company at
                <span className="underline text-blue-600">
                  support@savvycams.com or to Savvy Surveillance, LLC at PO BOX
                  681 Simonton, TX 77476.
                </span>
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  6. ACCESS TO ELECTRONIC COMMUNICATIONS.
                </span>{" "}
                <br />
                Company will send you a link to a copy of your Electronic
                Communications via email immediately after execution of
                agreement(s) or Electronic Communication is issued. YOU HEREBY
                ACKNOWLEDGE AND EXPRESSLY AFFIRM THAT YOU (i) OWN OR HAVE ACCESS
                TO AN ELECTRONIC DEVICE OR COMPUTER THAT MEETS THE SYSTEM
                REQUIREMENTS SET FORTH IN SECTION 7, AND THROUGH WHICH YOU ARE
                ABLE TO ACCESS YOUR EMAIL ADDRESS AND RECEIVE, VIEW, DOWNLOAD,
                SAVE AND PRINT ALL ELECTRONIC COMMUNICATIONS PROVIDED TO YOU BY
                THE COMPANY; AND (ii) OWN OR HAVE ACCESS TO A PRINTER THAT IS
                CAPABLE OF PRINTING ALL ELECTRONIC COMMUNICATIONS YOU RECEIVE
                FROM THE COMPANY.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black underline font-bold">
                  7. SYSTEM REQUIREMENTS.
                </span>{" "}
                <br />
                Specifically, in order to receive Electronic Communications from
                Company you must have a system that meets the following
                requirements:
              </p>
              <ul className="list-disc text-gray-500">
                <li className="text-justify">
                  An internet browser that supports 128-bit encryption, such as
                  Microsoft Internet Explorer Version 6.0 or above or Firefox
                  version 1.0 or above.
                </li>
                <li className="text-justify">
                  A personal computer (equipped with a 233Mhz processor and 800
                  x 600 screen resolution), operating system, and
                  telecommunications connections to the Internet capable of
                  receiving, accessing, displaying, and either printing or
                  storing electronic data received from us in electronic form or
                  by access to our website using one of the browsers mentioned
                  above.
                </li>
                <li className="text-justify">
                  Sufficient electronic storage capacity on your computer’s hard
                  drive or other data storage unit (at least 64 megabytes of
                  memory).
                </li>
                <li className="text-justify">
                  A printer capable of printing from your browser. Adobe Reader
                  version 7.0 or above.
                </li>
              </ul>
              <p className="text-gray-600 text-justify">
                To the extent required by law, Company shall notify you of any
                change made in the hardware or software requirements needed to
                access or participate in any electronic communication with
                Company, and you agree to re-consent electronically in any
                manner that reasonably demonstrates your ability to access
                Electronic Communications.
              </p>
            </div>
            <div className="my-4">
              <p className="text-gray-600 text-justify">
                <span className=" fw-bolder text-black  underline font-bold">
                  8. RIGHT TO MODIFY TERMS.
                </span>{" "}
                <br />
                Company reserves the right, in its sole discretion, to modify
                the terms and conditions of this eSign Consent. If required by
                law Company shall notify you of any modification to the eSign
                Consent or the termination of its relationship with you.
              </p>
            </div>
            <hr className="my-4" />
            {client?.agreement ? (
              <div>
                <p>
                  Latitude:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {client?.agreement?.coords?.latitude || "N/A"}
                  </span>
                </p>
                <p>
                  Longitude:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {client?.agreement?.coords?.longitude || "N/A"}
                  </span>
                </p>
                <p>
                  Location Name:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {client?.agreement?.location || "N/A"}
                  </span>
                </p>
              </div>
            ) : location ? (
              <div>
                <p>
                  Latitude:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {location.latitude}
                  </span>
                </p>
                <p>
                  Longitude:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {location.longitude}
                  </span>
                </p>
                <p>
                  Location Name:{" "}
                  <span className="text-gray-700 font-bold ml-4">
                    {locationName}
                  </span>
                </p>
              </div>
            ) : (
              <p>waiting for your location....</p>
            )}
            <hr className="my-4" />
            <p className="text-justify text-xl">
              Please sign below and click “ACCEPT” to accept the above terms and
              conditions and give your consent to executing the Agreement by
              electronic signature and to obtain all Documents and
              Communications from Company electronically.
            </p>
            <div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL ? (
                    <img
                      src={imageURL}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={client?.status==="Agreement signed"? null:toggle}
                    />
                  ) : (
                    <div
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle
                      }
                    />
                  )}
                  <p className="underline">Client 1 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  {imageURL2 ? (
                    <img
                      src={imageURL2}
                      alt=""
                      className="border p-3 h-20 w-60"
                      // onClick={ client?.status === "Agreement signed" ? null :toggle2}
                    />
                  ) : (
                    <div
                      onClick={
                        client?.status === "Agreement signed" ? null : toggle2
                      }
                      className="sigCanvas ring-1 ring-gray-700 font-bold h-20 w-60"
                    />
                  )}
                  <p className="underline">Client 2 Signature</p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
              <hr className="my-4" />
              <div className="block my-4 items-baseline justify-between lg:flex">
                <div>
                  <h1 className="text-xl text-red-600">Official Use Only </h1>
                  <img
                    src={client?.manager?.signature}
                    alt=""
                    className="border p-3 h-20 w-60"
                  />

                  <p className="underline">Manager Signed</p>
                  <p className="underline">
                    {client?.manager?.firstname} {client?.manager?.lastname}
                  </p>
                </div>

                <p>
                  {client?.agreement?.createdAt
                    ? moment(client?.agreement?.createdAt).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </p>
              </div>
            </div>

            {client?.status !== "Agreement signed" &&
              client?.status !== "Addendum signed" &&
              client?.status !== "Addendum Pending" && (
                <div className="my-10 mx-auto flex justify-between">
                  <button
                    // onClick={() => navigate("/")}
                    className="block text-center bg-white hover:bg-white focus:shadow-outline focus:outline-none text-[#0E669B] text-xs py-3 px-10 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    disabled={isPending}
                    className="block text-center shadow bg-green-900 hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
                  >
                    {isPending ? "Signing..." : "Agree and Submit"}
                  </button>
                </div>
              )}
          </div>
        </div>
      </section>
      <div className="w-full flex justify-between rounded-md lg:w-1/2 mx-auto m-10">
        <button
          onMouseOver={() => setIsDownload(true)}
          onMouseOut={() => setIsDownload(false)}
          onClick={handlePrint}
          className="flex text-center shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
        >
          <i class="ri-download-2-fill mr-2"></i>
          Download
        </button>
        {client?.status !== "Agreement signed" &&
          client?.status !== "Addendum signed" &&
          client?.status !== "Addendum Pending" && (
            <button
              onClick={() => setShowUpload(!showUpload)}
              className="flex text-center shadow ring-1 ring-[#0E669B] hover:bg-[#010101] hover:text-[#fcfdfd] focus:shadow-outline focus:outline-none text-xs py-3 px-10 rounded"
            >
              <i class="ri-upload-cloud-2-line mr-2"></i>
              Upload copy
            </button>
          )}
      </div>
      {showUpload && (
        <>
          <UploadAgreementModal
            toggle={() => setShowUpload(!showUpload)}
            data={client}
            cancel_before={threeBusinessWorkingDays()[2]}
          />
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      <Signature
        toggle={toggle}
        isOpen={isOpen}
        sigCanvas={sigCanvas}
        imageURL={imageURL}
        clear={clear}
        save={save}
      />
      <Signature2
        toggle2={toggle2}
        isOpen2={isOpen2}
        sigCanvas2={sigCanvas2}
        imageURL2={imageURL2}
        clear2={clear2}
        save2={save2}
      />
    </>
  );
}
